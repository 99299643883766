<template>
  <q-page class="column full-width window-height">
    <q-scroll-area
      class="full-width mask-overflow-b"
      ref="scrollArea"
      :style="pageStyle"
    >
      <q-responsive
        :ratio="16 / 9"
        class="mask-overflow-b relative-positon"
        style="overflow: hidden"
      >
        <div class="relative-position">
          <transition-group
            appear
            enter-active-class="animated fadeIn slower"
            leave-active-class="animated fadeOut"
          >
            <video-component
              v-if="splashClicked"
              active
              key="video"
              mute
              :project="project"
              poster="~/assets/thumb.jpg"
              src="https://d2hsqudh7e0r2l.cloudfront.net/out/v1/d9a9f5c3216a4bfe993ef938e87ed9da/4881d9cacdf84b5c99152ce274631423/49f210203e6b43868c1870b71e4bebc7/index.m3u8"
            />
            <div key="headline" class="absolute-full flex flex-center">
              <span
                :class="
                  $q.screen.lt.md
                    ? $q.screen.gt.xs
                      ? 'q-pa-lg text-h3'
                      : 'q-pa-lg text-h5'
                    : $q.screen.lt.lg
                    ? 'q-pa-xl text-h2'
                    : 'q-pa-xl text-h1'
                "
                class="text-white"
                style="line-height: 1; max-width: 86vw"
                >{{ t("headline") }}</span
              >
            </div>
          </transition-group>
        </div>
      </q-responsive>
      <div class="row items-center justify-center q-pb-md q-pt-xl">
        <div :class="contentClass">
          <p class="dm-sans-700 text-h6 text-primary">{{ t("org") }},</p>
        </div>
      </div>
      <template
        v-for="(content, index) in app.letter.content"
        :key="content._key"
      >
        <div
          :class="$q.screen.gt.sm ? 'scroll-section' : void 0"
          class="row items-center justify-center q-pb-xl"
        >
          <component
            :data="content"
            :index="index"
            :is="isComponent(content._type)"
          />
        </div>
      </template>
      <div class="full-width justify-center q-mb-xl q-pb-xl row">
        <q-btn
          color="dark"
          download
          :href="pdfLink"
          label="Download Resume"
          square
          target="_blank"
        />
      </div>
    </q-scroll-area>
    <q-page-sticky
      position="top-right"
      :offset="$q.screen.lt.md ? [12, 14] : [8, 22]"
    >
      <q-btn color="white" dense flat icon="menu" @click="onClick()" />
    </q-page-sticky>
  </q-page>
</template>

<script setup>
import { computed, nextTick, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useAppStore } from "src/stores/app";
import BodyComponent from "src/components/body/BodyComponent.vue";
import ProjectsComponent from "src/components/project/ProjectsComponent.vue";
import VideoComponent from "src/components/video/VideoComponent.vue";

defineOptions({ name: "IndexPage" });

const { t } = i18n.global;
const appStore = useAppStore();
const { app, contentClass, drawer, splashClicked } = storeToRefs(appStore);
const pageHeight = ref("100vh");
const pageStyle = computed(() => {
  return { minHeight: pageHeight.value };
});
const project = {
  slug: {
    current: "foundation",
  },
};
const pdfLink = "/ChrisQuezada-CV.pdf";
const scrollArea = ref(null);

const isComponent = (type) => {
  return type === "bodyBlock" ? BodyComponent : ProjectsComponent;
};

const setScrollContainerStyle = () => {
  const container = scrollArea.value?.$el?.querySelector(
    ".q-scrollarea__container"
  );
  if (container) {
    container.style.minHeight = pageHeight.value;
    container.style.scrollSnapType = "y mandatory";
  }
};

const styleFn = (offset) => {
  pageHeight.value = offset ? `calc(100vh - ${offset}px)` : "100vh";
};

const onClick = () => {
  drawer.value = !drawer.value;
};

watch(pageHeight, async () => {
  await nextTick();
  setScrollContainerStyle();
});
</script>
